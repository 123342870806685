import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { AppearText, AppearImage } from '../common/appear'
const Item = styled.a`
    width: 50%;
    height: 178px;
    display: flex;
    @media (max-width: 600px) {
        width: 100%;
        height: 93px;
        margin-top: 30px;
    }
    &:hover {
        cursor: pointer;
    }
`;

const ImageWrapper = styled.div`
    width: 178px;
    height: 178px;
    position: relative;
    @media (max-width: 600px) {
        width: 93px;
        height: 93px;
    }
`;

const Image = styled.img`
    width: 118px;
    height: 118px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease;
    width: ${props => props.hover && '155px'};
    height: ${props => props.hover && '155px'};
    background: #00A8E3;
    background-size: ${props => props.icon && '70% auto'};
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @media (max-width: 600px) {
        width: 93px;
        height: 93px;
        width: ${props => props.hover && '93px'};
        height: ${props => props.hover && '93px'};
    }
`;

const TextWrapper = styled.div`
    width: 50%;
    height: 178px;
    position: relative;
    @media (max-width: 600px) {
        height: 93px;
    }
`;

const H3 = styled.h3`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-family: 'Lato';
    font-size: 30px;
    line-height: 120%;
    color: #0064BA;
    transition: all 0.2s ease;
    font-weight: 500;
    font-weight: ${props => props.hover && '700'};
    @media (max-width: 600px) {
        font-size: 20px;
        line-height: 24px;
        /* or 24px */
        left: 25px;
    }
`;
const EVENT_NAME = 'start-transition';
const PersonalItem = ({
    title,
    url,
    image,
    icon,
    lang,
    section = 'personal'
}) => {
    const [ hover, setHover ] = useState(false);

    const handleMouseOver = () => {
        setHover(true);
    }
    const handleMouseLeave = () => {
        setHover(false);
    }
    const handleClick = (evt) => {
        evt.preventDefault();
        const event = new Event(EVENT_NAME);
        window.dispatchEvent(event);
        if (typeof window !== 'undefined' && window.scrollLib) {
            window.scrollLib.scrollTo(0);
        }
       setTimeout(() => {
            navigate(`/${lang}/${section}/${url}`);
        }, 750);
    }
    return <Item
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={evt => handleClick(evt)}
            href={`/${lang}/${section}/${url}`}
        >
            <ImageWrapper>
                <AppearImage>
                {
                    image ? <Image
                        alt=""
                        src={`${image || icon}`}
                        icon={!image}
                        hover={hover}
                    /> :
                    <Image src="" alt="" style={{ backgroundImage: 'url(' + icon + ')'}} hover={hover} />
                }
               </AppearImage>
            </ImageWrapper>
            <TextWrapper>
            <H3 hover={hover}>
                <AppearText>{title}</AppearText>
            </H3>
            </TextWrapper>
    </Item>;
};

export default PersonalItem;
